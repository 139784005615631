import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      toast.error('Por favor, selecciona un archivo para subir.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    axios.post('https://back.delareinashop.com/procesar-script', formData)
      .then(response => {
        console.log(response.data);
        checkProgress();
        toast.success('Script subido correctamente.');
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        toast.error('Error al subir el archivo.');
      });
  };

  const checkProgress = () => {
    const interval = setInterval(() => {
      axios.get('https://back.delareinashop.com/progreso')
        .then(response => {
          setProgress(response.data);
          if (response.data >= 100) {
            clearInterval(interval);
          }
        })
        .catch(error => {
          console.error('Error checking progress:', error);
          clearInterval(interval);
        });
    }, 1000);
  };

  return (
    <div className="App">
      <h1>Subir y Procesar Script SQL</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Subir y Procesar</button>
      <div className="progress-bar">

      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
